<template>
  <div class="row">
    <div class="col-sm-4">
      <div class="card card-primary card-outline">
        <div class="card-header">
          <h5 class="card-title m-0">
            <i class="fa fa-info-circle"></i> Information
          </h5>
          <div class="card-tools">
            <router-link :to="backURL"
              ><i class="fa fa-arrow-left"></i> back
            </router-link>
          </div>
        </div>
        <div class="card-body">
          <div v-if="skeleton.info" class="mb-4">
            <b-skeleton></b-skeleton>
            <b-skeleton></b-skeleton>
          </div>
          <template v-else>
            <template v-if="typeof xhr.info.rating !== 'undefined'">
              <div class="text-center" v-html="ratingHTML"></div>
            </template>
            <template v-else>
              <div class="text-center">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <p>
                  <i>Customer belum memasukan rating</i>
                </p>
              </div>
            </template>
          </template>

          <b-card>
            <div v-if="skeleton.info">
              <b-skeleton animation="fade" width="85%"></b-skeleton>
              <b-skeleton animation="fade" width="55%"></b-skeleton>
              <b-skeleton animation="fade" width="70%"></b-skeleton>
              <b-skeleton animation="fade" width="55%"></b-skeleton>
              <b-skeleton animation="fade" width="85%"></b-skeleton>
            </div>
            <table v-else class="table table-borderless table-sm">
              <tr>
                <td>ID</td>
                <td>:</td>
                <td>
                  <b>#{{ xhr.info.thread.id }}</b> &nbsp;
                  <span class="badge badge-info"
                    >sub {{ xhr.info.sub.id }}</span
                  >
                </td>
              </tr>
              <tr>
                <td>Name</td>
                <td>:</td>
                <td>{{ xhr.info.thread.nama }}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>:</td>
                <td>{{ xhr.info.thread.email }}</td>
              </tr>
              <tr>
                <td>Phone</td>
                <td>:</td>
                <td>{{ xhr.info.thread.phone }}</td>
              </tr>
              <tr>
                <td>IP</td>
                <td>:</td>
                <td>{{ xhr.info.sub.ip }}</td>
              </tr>
              <tr v-if="xhr.info.sub.end_time === null">
                <td>Started</td>
                <td>:</td>
                <td>{{ mixGetAgo(xhr.info.sub.start_time) }} Minutes Ago</td>
              </tr>
              <tr>
                <td>Referrer</td>
                <td>:</td>
                <td>{{ xhr.info.thread.referrer }}</td>
              </tr>
            </table>
          </b-card>
          <div v-if="skeleton.info">
            <b-skeleton animation="fade" width="85%"></b-skeleton>
            <b-skeleton animation="fade" width="55%"></b-skeleton>
            <b-skeleton animation="fade" width="70%"></b-skeleton>
          </div>
          <div v-else>
            <p><i class="fa fa-users"></i> Agent Handle</p>
            <ul>
              <li v-for="(agent, key) in xhr.info.agents" :key="key">
                {{ agent.agent }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-8">
      <div class="card card-primary card-outline">
        <div class="card-header">
          <h5 class="card-title m-0">
            <i class="fa fa-comments"></i> Transcript Chat
          </h5>

          <div class="card-tools">
            <a
              class="text-muted"
              href="#"
              @click.stop.prevent="onDownloadTranscript"
              ><i class="fa fa-download"></i> Download</a
            >
          </div>
        </div>
        <div class="card-body">
          <template v-if="skeleton.chat">
            <b-skeleton animation="fade" width="85%"></b-skeleton>
            <b-skeleton animation="fade" width="55%"></b-skeleton>
            <b-skeleton animation="fade" width="70%"></b-skeleton>
            <b-skeleton animation="fade" width="70%"></b-skeleton>
            <br />
            <b-skeleton animation="fade" width="85%"></b-skeleton>
            <b-skeleton animation="fade" width="55%"></b-skeleton>
            <b-skeleton animation="fade" width="55%"></b-skeleton>
            <b-skeleton animation="fade" width="70%"></b-skeleton>
            <br />
            <b-skeleton animation="fade" width="85%"></b-skeleton>
            <b-skeleton animation="fade" width="85%"></b-skeleton>
            <b-skeleton animation="fade" width="55%"></b-skeleton>
            <b-skeleton animation="fade" width="70%"></b-skeleton>
          </template>
          <div v-else class="chat-box p-2">
            <template v-for="(row, key) in xhr.chat">
              <!--for customer-->
              <div
                v-if="row.actor === 'customer'"
                :key="key"
                class="direct-chat-msg"
              >
                <div class="direct-chat-infos clearfix">
                  <span class="direct-chat-name float-left">{{
                    row.name
                  }}</span>
                  <span class="direct-chat-timestamp float-right">{{
                    row.created_at
                  }}</span>
                </div>
                <img
                  :src="`/${image.customer}`"
                  alt="rw"
                  class="direct-chat-img"
                />
                <div class="direct-chat-text">
                  <span v-if="row.tipe === 'image'">
                    <a :href="`${baseImage}/${row.message}`" target="_blank">
                      <img
                        :src="`${baseImage}/${row.message}`"
                        alt=""
                        loading="lazy"
                      /> </a
                  ></span>
                  <span
                    v-else-if="row.tipe === 'file'"
                    class="d-flex align-items-center"
                  >
                    <svg>
                      <use xlink:href="#rw-file-download"></use>
                    </svg>
                    &nbsp;
                    <a
                      :href="`${baseFile}/${row.message}`"
                      class="text-muted"
                      target="_blank"
                      >Download</a
                    >
                  </span>
                  <span v-else v-html="row.message"></span>
                </div>
              </div>

              <!--for agent -->
              <div
                v-if="row.actor === 'staff'"
                :key="key"
                class="direct-chat-msg right"
              >
                <div class="direct-chat-infos clearfix">
                  <span class="direct-chat-name float-right"
                    >Agent : {{ row.name }}</span
                  >
                  <span class="direct-chat-timestamp float-left">{{
                    row.created_at
                  }}</span>
                </div>
                <!-- /.direct-chat-infos -->
                <img
                  :src="`/${image.staff}`"
                  alt="rw"
                  class="direct-chat-img"
                />
                <!-- /.direct-chat-img -->
                <div class="direct-chat-text">
                  <span v-if="row.tipe === 'image'"
                    ><a :href="`${baseImage}/${row.message}`" target="_blank">
                      <img
                        :src="`${baseImage}/${row.message}`"
                        alt=""
                        loading="lazy"
                      /> </a
                  ></span>
                  <span
                    v-else-if="row.tipe === 'file'"
                    class="d-flex align-items-center"
                  >
                    <svg>
                      <use xlink:href="#rw-file-download"></use>
                    </svg>
                    &nbsp;
                    <a
                      :href="`${baseFile}/${row.message}`"
                      class="text-white"
                      target="_blank"
                      >Download</a
                    >
                  </span>
                  <span v-else v-html="row.message"></span>
                </div>
                <!-- /.direct-chat-text -->
              </div>

              <!-- for system /bot -->
              <p
                v-if="row.actor === 'system'"
                :key="key"
                style="margin-bottom: 0 !important"
              >
                <small
                  ><i class="fa fa-volume-up"></i> {{ row.created_at }}
                  <i>{{ row.message }}</i></small
                >
              </p>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { download, request, waktu } from "@/common/mixins/mix_helper";
import { FILES_URL, image, IMAGE_URL } from "@/common/config";
import { loader } from "@/common/mixins/mix_loader";

export default {
  name: "History",
  mixins: [request, waktu, download, loader],
  data() {
    return {
      /**
       * --------------------------------------------------------------
       * xhr : {info && chat}
       * --------------------------------------------------------------
       */
      xhr: {
        info: [],
        chat: [],
      },
      success: false,
      skeleton: {
        info: true,
        chat: true,
      },
      image: image,
      backURL: "/panel/chat",
      baseImage: IMAGE_URL,
      baseFile: FILES_URL,
      ratingHTML: "",
    };
  },
  methods: {
    /**
     * --------------------------------------------------------------
     * request detail thread active
     * --------------------------------------------------------------
     */
    getHistory: function () {
      if (typeof this.$route.params.id !== "undefined") {
        this.API.post(this.URL.thread.detail, {
          subthreadid: this.$route.params.id,
        })
          .then(({ data }) => {
            if (data.data.chat.length !== 0 && data.data.info.length !== 0) {
              this.xhr.chat = data.data.chat;
              this.xhr.info = data.data.info;
              this.success = true;
              if (typeof data.data.info.rating !== "undefined") {
                let jmlBintang = data.data.info.rating.rating;
                for (let i = 0; i < 5; i++) {
                  if (i < jmlBintang) {
                    this.ratingHTML +=
                      "<i class='fa fa-star text-warning'></i>";
                  } else {
                    this.ratingHTML += "<i class='fa fa-star text-muted'></i>";
                  }
                }
                this.ratingHTML += `<p>${data.data.info.rating.message}</p>`;
              }
            } else {
              this.xhr.info = "Something wrong.";
              this.xhr.chat = "Something wrong.";
            }
          })
          .catch((err) => {
            this.xhr = err;
          })
          .finally(() => {
            /**
             * --------------------------------------------------------------
             * biar keliahatan loading skeletonya :)
             * --------------------------------------------------------------
             */
            setTimeout(() => {
              this.skeleton.info = false;
              this.skeleton.chat = false;
            }, 1000);
          });
      } else {
        this.xhr.info = "Missing params subid";
      }
    },

    onDownloadTranscript() {
      this.loading();
      this.API.post(this.URL.message.export, {
        threadid: this.xhr.info.thread.idhash,
        subid: this.xhr.info.sub.id,
      })
        .then(({ data }) => {
          let chat = data.data;
          let customer = chat.customer;
          let transcriptChat = `------------------ CUSTOMER ----------------------\n`;
          transcriptChat += `IP: ${customer.ip}\nNama: ${customer.nama}\nEmail: ${customer.email}\nReferrer: ${customer.referrer}`;
          transcriptChat += `\n\n------------------ TRANSCRIPT CHAT ------------------------------------\n\n`;
          chat.messages.forEach((item) => {
            transcriptChat += `[${item.timestamp}] ${item.nama} : ${item.message}\n`;
          });
          this.download(transcriptChat, `${chat.fileName}-${customer.email}`);
        })
        .catch(() => {
          window.alert("Something wrong!");
        })
        .finally(() => {
          this.loading(false);
        });
    },
  },
  mounted() {
    this.getHistory();
    this.$store.dispatch("breadcrumb", {
      title: "History Page",
      path: "History",
    });
    this.subid = this.$route.params.id;

    /**
     * --------------------------------------------------------------
     * check ref for backurl
     * --------------------------------------------------------------
     */
    if (typeof this.$route.query.ref !== "undefined") {
      if (this.$route.query.ref === "history") {
        this.backURL = "/panel/history";
      }
      if (this.$route.query.ref === "rating") {
        this.backURL = "/panel/rating";
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.right > .direct-chat-text {
  background-color: #46ccff !important;
  border-color: #46ccff !important;
  color: #fff;
}

.right .direct-chat-text::after,
.right .direct-chat-text::before {
  border-left-color: #46ccff;
}

.chat-box {
  height: 400px;
  overflow-y: auto;
}

.chat-box::-webkit-scrollbar {
  width: 5px;
}

.chat-box::-webkit-scrollbar-thumb {
  border-left: 0;
  border-right: 0;
  background-color: #16b5ec;
}

.direct-chat-text {
  svg {
    width: 1rem;
    height: 1rem;
    fill: #444;
  }

  img {
    height: 4em;
  }
}
</style>
